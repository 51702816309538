$(document).ready(function()
{
    /**
     * BODY CLASS HANDLING
     */
    $('body').placeholderFix().browserNotice();

    // Toggle the "hovering" class on elements
    $('.tapHover').on('touchend', tapHover);

    /**
     * ACCORDION CALL
     */
    $('.accHandler').accordion({openFirst:true});

    /**
     * animate
    */
    $(window).scroll(function(){
      $('.in-view').each(function () {
       inViewPort($(this), 0);
      });
    });

    function inViewPort(elm, timeout){
      var $elm = $(elm);
      setTimeout(function(){
          if($elm.length){
              var windowHeight = ($(window).height() / 1.5) + $(document).scrollTop(),
                    emlOffsetTop = $elm.offset().top;
              if(emlOffsetTop<windowHeight){
                  $elm.addClass('ready');
              }
          }
      },timeout);
    }

    /**
     * ESCAPE THE MAILMASK LINKS
     */
    $('a.escape').noSpam();

    /**
     * FORM VALIDATION AND SUBMIT VIA AJAX
     */
    $('form[data-validate="true"]').each(function()
    {
        $(this).validate();
    });

    /**
     * CALL COLORBOX FOR IMAGES
     */
    $('a[data-rel^=lightbox]:not(.nolightbox)').lightcase({
        showCaption: false,
        labels: lightcaseLabels,
        maxWidth: $(window).width() * 0.8,
        maxHeight: $(window).height() * 0.8,
        shrinkFactor:1,
        type: 'image'
    });

    /**
     * CALL COLORBOX FOR EXTERNAL LINKS / YOUTUBE VIDEOS / ETC.
     */
    // $('a[data-rel^=lightbox-external]:not(.nolightbox)').lightcase({
    //     showCaption: false,
    //     labels: lightcaseLabels,
    //     type: 'iframe',
    //     maxWidth: $(window).width() * 0.8,
    //     maxHeight: $(window).height() * 0.8,
    //     iframe: {
    //         width: '600',
    //         height: $(window).height() * 0.8
    //     }
    // });

    /**
     * GET BREAKPOINTS FROM _CONFIG.SCSS FOR IF-STATEMENTS
     */
    if ( breakpoint['medium'] ) {
        $('.page-navi').stick_in_parent({
            parent: 'body'
        });
    }

    /**
     * detect external links and add target=_blank
     */
    $('a:external').attr('target', '_blank');


    /**
     * PREVENTS CONTENT SCROLLING AFTER TOGGELING
     */
    // naviToggled.onchange('change');

});
/////////////// END DOC READY

/**
* PLACE YOUR OWN SCRIPTS HERE
*/